.Outspace {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 551px;
        height: 419px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 238px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 196px;
        }
    }

    &Image3 {
        width: 530px;
        height: 430px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 304px;
            width: 363px;
            height: 294px;
            object-fit: cover;
        }

        @media (min-width:360px) and (max-width: 430px) {
            // width: 241px;
            width: 268px;
            height: 218px;
            object-fit: cover;
        }
    }

    &Image4 {
        width: 551px;
        height: 409px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 233px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 191px;
        }
    }

    &Image5 {
        width: 530px;
        height: 430px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 304px;
            width: 363px;
            height: 294px;
            object-fit: cover;
        }

        @media (min-width:360px) and (max-width: 430px) {
            // width: 241px;
            width: 268px;
            height: 218px;
            object-fit: cover;
        }
    }

    &Image6 {
        width: 551px;
        height: 341px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 194px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 159px;
        }
    }
}