@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');

.PortfolioItem {
    box-sizing: border-box;
    text-decoration: none !important;
    padding-top: 20px;
    padding-right: 60px;

    &:active {
        cursor: grab;
    }
    &:focus {
        cursor: pointer !important;
    }
    @media (min-width: 1728px) and (max-width: 1920px) {
        & {
            padding-top: 18px;
            padding-right: 58px;
        }
    }

    @media (min-width: 1512px) and (max-width: 1728px) {
        & {
            padding-top: 16px;
            padding-right: 56px;
        }
    }

    @media (min-width: 1366px) and (max-width: 1512px) {
        & {
            padding-top: 14px;
            padding-right: 54px;
        }
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        & {
            padding-top: 10px;
            padding-right: 32px;
        }
    }

    &ImageWrapper {
        width: fit-content;
        height: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0;

        &::after {
            content: "";
            border: 2px solid #000000;
            width: 508px;
            height: 340px;
            position: absolute;
            top: -20px;
            left: 20px;
            z-index: -1;
            display: block;
            box-sizing: border-box;

            @media (min-width: 1728px) and (max-width: 1920px) {
                & {
                    width: 460px;
                    height: 308px;
                    top: -18px;
                    left: 18px;
                }
            }

            @media (min-width: 1512px) and (max-width: 1728px) {
                & {
                    width: 395px;
                    height: 265px;
                    top: -16px;
                    left: 16px;
                }
            }

            @media (min-width: 1366px) and (max-width: 1512px) {
                & {
                    width: 357px;
                    height: 239px;
                    top: -14px;
                    left: 14px;
                }
            }

            @media (min-width: 320px) and (max-width: 1366px) {
                & {
                    width: 263px;
                    height: 176px;
                    top: -10px;
                    left: 10px;
                }
            }
        }

    }

    &ImageWrapperGreen {
        &::after {
            background: #38D9B2;
            border: 0px;
        }
    }

    &ImageWrapperPurple {
        &::after {
            background: #EC52EF;
            border: 0px;
        }
    }

    &Image {
        width: 508px;
        height: 340px;
        position: relative !important;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        &:hover {
            filter: brightness(20%);
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }
        &Hover {
            width: auto;
            position: relative;


        }
        &Hover:hover  &HoverTxt{
            opacity: 1;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1);
            visibility: visible;
        }

        &HoverTxt {
            position: absolute;
            display: flex;
            z-index: 999;
            top: 141px;
            color: #FFF;
            font-family: 'Thabit';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
            opacity:0;
            pointer-events: none;
            visibility: hidden;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1);
            justify-content: center;
            align-items: center;
            width: 100%;

            @media (min-width: 1512px) and (max-width: 1728px) {
                & {
                    top: 119px;
                }
            }
    
            @media (min-width: 1366px) and (max-width: 1512px) {
                & {
                    top: 107px;
                }
            }
    
            @media (min-width: 320px) and (max-width: 1366px) {
                & {
                    font-size: 16px;
                    top: 77px;
                }
            }
        }

        @media (min-width: 1728px) and (max-width: 1920px) {
            & {
                width: 460px;
                height: 308px;
            }
        }

        @media (min-width: 1512px) and (max-width: 1728px) {
            & {
                width: 395px;
                height: 265px;
            }
        }

        @media (min-width: 1366px) and (max-width: 1512px) {
            & {
                width: 357px;
                height: 239px;
            }
        }

        @media (min-width: 320px) and (max-width: 1366px) {
            & {
                width: 263px;
                height: 176px;
            }
        }
    }

    &TagWrapper {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 16px;

        @media (min-width: 1512px) and (max-width: 1728px) {
            & {
                width: 402px;
            }
        }

        @media (min-width: 320px) and (max-width: 1512px) {
            & {
                max-width: 263px;
                flex-wrap: wrap;
                height: 68px;
            }
        }

        @media (min-width: 320px) and (max-width: 744px) {
            & {
                height: 62px;
            }
        }
    }

    &TagElement {
        font-family: 'Thabit';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        border: 2px solid #000000;
        border-radius: 20px;
        padding: 0 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 28px;


        @media (min-width: 320px) and (max-width: 744px) {
            & {
                font-size: 10px;
                line-height: 13px;
                height: 25px;
            }
        }

        &Filter {
            background-color: black;
            color: white;
        }
    }
}