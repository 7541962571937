.BankGrant {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 555px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 316px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 258px;
            height: 174px;
        }
    }

    &Image3 {
        width: 720px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 494px;
            // height: 295px;
            width: 542px;
            height: 324px;
            object-fit: cover;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 366px;
            height: 218px;
            object-fit: cover;
        }
    }

    &Image4 {
        width: 557px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 318px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 259px;
            height: 174px;
        }
    }

    &Image5 {
        width: 720px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 494px;
            // height: 295px;
            width: 542px;
            height: 324px;
            object-fit: cover;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 366px;
            height: 218px;
            object-fit: cover;
        }
    }

    &Image6 {
        width: 497px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 284px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 231px;
            height: 174px;
        }
    }

    &Image7 {
        width: 720px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 494px;
            // height: 295px;
            width: 542px;
            height: 324px;
            object-fit: cover;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 366px;
            height: 218px;
        }
    }
}