@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');



.footer {
  &Container {
    display: block;
    
    max-height: fit-content;
    padding-top: 32px;
  }
}

.footerLogo {
  position: absolute;
  width: 1066px;
  height: 77px;
  top: 50%;
  left: 50%;
  margin-left: -536px;
}

.footerWrapper {
  width: 100%;
  height: 262px;
  display: flex;
  flex-direction: row;
  padding-left: 80px;
  padding-right: 80px;
  justify-content: space-between;
  align-items: flex-end;
  background-size: 1130px 77px;
  background: url(../images/LogoFooter.svg) center bottom no-repeat;
}

.footerText {
  font-family: 'Thabit', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 21px;
  margin-bottom: 32px;

  &Arrow {
    width: 7px;
    margin-right: 8px;
  }
}

.btn {
  cursor: pointer;
}


@media (min-width: 1728px) and (max-width: 1920px) {
  .footerText {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 32px;

    &Arrow {
      width: 7px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 251px;
    padding-left: 64px !important;
    padding-right: 64px !important;
    background-size: 987px 77px !important;
    background: url(../images/LogoFooter.svg) center 103% no-repeat;
  }
}

@media (min-width: 1511px) and (max-width: 1728px) {
  .footerText {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 32px;

    &Arrow {
      width: 7px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 221px;
    padding-left: 64px !important;
    padding-right: 64px !important;
    background-size: 867px 77px !important;
    background: url(../images/LogoFooter.svg) center 107% no-repeat;
  }
}

@media (min-width: 1365px) and (max-width: 1511px) {
  .footerText {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 32px;

    &Arrow {
      width: 7px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 206px;
    padding-left: 64px !important;
    padding-right: 64px !important;
    background-size: 824px 75px !important;
    background: url(../images/LogoFooter.svg) center 113% no-repeat;
  }
}

@media (min-width: 1023px) and (max-width: 1365px) {
  .footerText {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 32px;

    &Arrow {
      width: 7px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 165px;
    padding-left: 32px !important;
    padding-right: 32px !important;
    background-size: 649px 69px !important;
    background: url(../images/LogoFooter.svg) center 114% no-repeat;
  }
}

@media (min-width: 743px) and (max-width: 1023px) {
  .footerText {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 64px;

    &Arrow {
      width: 7px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 98px;
    padding-left: 32px !important;
    padding-right: 32px !important;
    background-size: 428px 26px !important;
    background: url(../images/LogoFooter.svg) center 100% no-repeat;
  }
}

@media (min-width: 430px) and (max-width: 743px) {
  .footerText {
    margin-bottom: 64px;
    font-size: 10px;
    line-height: 13px;

    &Arrow {
      width: 7px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 99px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    background-size: 351px 27px !important;
    background: url(../images/LogoFooter.svg) center 104% no-repeat;
  }
}


@media (min-width: 360px) and (max-width: 430px) {
  .footerText {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 64px;

    &Arrow {
      width: 5px;
      margin-right: 8px;
    }
  }


    .footerWrapper {
    align-items: flex-end;
    height: 99px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    background-size: 351px 27px !important;
    background: url(../images/LogoFooter.svg) center 104% no-repeat;
  }
}


@media (min-width: 300px) and (max-width: 360px) {
  .footerText {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 64px;

    &Arrow {
      width: 5px;
      margin-right: 8px;
    }
  }

  .footerWrapper {
    align-items: flex-end;
    height: 102px;
    padding-left: 16px;
    padding-right: 16px;
    background-size: 280px 34px !important;
    background: url(../images/LogoFooter.svg) center 115% no-repeat;
  }
}


@media (min-width: 10px) and (max-width:300px) {
  .footerText {
    font-size: 8px;
    line-height: 10px;

    &Arrow {
      width: 5px;
      margin-right: 4px;
      height: 7px;
    }
  }

  .footerWrapper {
    padding-left: 16px;
    padding-right: 16px;
    background-size: 0 34px !important;
    background: url(../images/LogoFooter.svg) center bottom no-repeat;
  }
}