.EnlilMill {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 497px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 284px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 231px;
            height: 174px;
        }
    }

    &Image3 {
        width: 496px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 283px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 231px;
            height: 174px;
        }
    }

    &Image4 {
        width: 557px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 318px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 259px;
            height: 174px;
        }
    }

    &Image5 {
        width: 407px;
        height: 332px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 232px;
            height: 190px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 189px;
            height: 155px;
        }
    }

    &Image6 {
        width: 406px;
        height: 332px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 232px;
            height: 190px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 189px;
            height: 155px;
        }
    }

    &Image7 {
        width: 557px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 317px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 259px;
            height: 174px;
        }
    }

    &Image8 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image9 {
        width: 420px;
        height: 404px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image10 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image11 {

        width: 150px;
        height: 404px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 86px;
            height: 231px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 70px;
            height: 188px;
        }
    }
}