.Sketchpad {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image3 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image4 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image5 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image6 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image7 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image8 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image9 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image10 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }
}