@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');

.ContactBtn {
    cursor: pointer;
    position: absolute;
    left: 50%;
    z-index: 999 !important;
    width: 150px;
    height: 150px;
    border-radius: 120px;
    background: #38D9B2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -75px;
    box-sizing: border-box;
    transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

    &:hover {
        width: 165px;
        height: 165px;
        margin-left: -82.5px;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        margin-top: -8px;
    }

    &Open {
        background: #FFFFFF !important;
        border: 2px solid #000000 !important;
    }

    &Top {
        position: absolute;
        left: unset !important;
        z-index: 999 !important;
        width: 150px;
        height: 150px;
        border-radius: 120px;
        background: #38D9B2;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

    }

    &OpenTop {
        background: #FFFFFF !important;
        border: 2px solid #000000 !important;
        left: unset !important;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 165px;
            height: 165px;
            margin-left: -82.5px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }
    }

    &Text {
        font-family: 'Thabit', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }

    &Modal, &ModalTop {
        position: absolute;
        width: 355px;
        height: 212px;
        left: calc(50% + 100px);
        top: 3300px;
        background: #FFFFFF;
        border: 2px solid #000000;
        border-radius: 137px;
        z-index: 9999 !important;

        &:active, &Top:active,&:hover,&Top:hover{
            cursor: grab;
        }


        &Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 20px;
        }

        &CloseWrapper {
            margin-top: 16px;
            display: flex;
            margin-bottom: 16px;
            justify-content: center;
            align-items: center;
        }

        &Close {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 8px;
            padding-right: 8px;
            width: 16px;
            height: 16px;
            box-sizing: content-box !important;
            
            display: block;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }


        &Telegram {
            width: 40px;
            height: 40px;
        }

        &Text {
            font-family: 'Thabit';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            text-decoration-line: underline;
            color: #000000;
        }
    }

    &ModalTop {
        top: 89px;
        left: 440px;
    }

    &PortfolioModal {
        top: 1100px !important;
    }
}

.ContactBtnWrapper {
    width: 200px;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
}

.ContactBtnWrapperSafari {
    // position: relative;
    margin-left: 369px;

    @media (min-width: 1727px) and (max-width: 1919px) {
        margin-left: 343px;
    }

    @media (min-width: 1511px) and (max-width: 1727px) {
        margin-left: 254px;
    }

    @media (min-width: 1365px) and (max-width: 1511px) {
        margin-left: 263px;
    }

    @media (min-width: 1023px) and (max-width: 1365px) {
        margin-left: 199px;
    }

    @media (min-width: 743px) and (max-width: 1023px) {
        margin-left: 137px;
    }

    @media (min-width: 429px) and (max-width: 743px) {
        margin-left: 110px;
    }

    @media (min-width: 320px) and (max-width: 429px) {
        margin-left: 98px;
    }
}

.ContactBtnModalSafari {
    left: 444px !important;
    top: 89px;
    @media (min-width: 1727px) and (max-width: 1919px) {
        left: 413px !important;
        top: 88px !important;
    }

    @media (min-width: 1511px) and (max-width: 1727px) {
        left: 314px !important;
        top: 113px !important;
    }

    @media (min-width: 1365px) and (max-width: 1511px) {
        left: 309px !important;
        top: 81px !important;
    }

    @media (min-width: 1023px) and (max-width: 1365px) {
        left: 242px !important;
        top: 57px !important;
    }

    @media (min-width: 743px) and (max-width: 1023px) {
        left: 165px !important;
        top: 38px !important;
    }

    @media (min-width: 429px) and (max-width: 743px) {
        left: 58px !important;
        top: 101px !important;
    }

    @media (min-width: 320px) and (max-width: 429px) {
        left: 3px !important;
        top: 95px !important;
    }
}

@media (min-width: 1921px) {
    .ContactBtnPortfolioModal {
        top: 1150px !important;
    }
}

@media (min-width: 1727px) and (max-width: 1919px) {
    .ContactBtn {
        width: 140px;
        height: 140px;
        margin-left: -70px;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 155px;
            height: 155px;
            margin-left: -77.5px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }

        &Text {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
        }

        &Modal {
            top: 3250px;
        }
        &PortfolioModal {
            top: 1100px !important;
        }
    }
}

@media (min-width: 1511px) and (max-width: 1727px) {
    .ContactBtn {
        width: 120px;
        height: 120px;
        margin-left: -60px;

        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 140px;
            height: 140px;
            margin-left: -70px;
            margin-top: -10px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }
        &Text {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }
        &ModalTop {
            top: 89px;
            left: 350px;
        }
        &Modal {
            top: 3150px;
        }
        &PortfolioModal {
            top: 1050px !important;
        }
    }

}

@media (min-width: 1365px) and (max-width: 1511px) {
    .ContactBtn {
        width: 100px;
        height: 100px;
        margin-left: -50px;

        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 120px;
            height: 120px;
            margin-left: -60px;
            margin-top: -10px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }

        &Text {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
        }

        &ModalTop {
            top: 80px;
            left: 310px;
        }

        &PortfolioModal {
            top: 960px !important;
        }
    }
}

@media (min-width: 1510px) and (max-width: 1513px) {
    .ContactBtnModal {
        top: 3100px;
    }
}

@media (min-width: 1367px) and (max-width: 1510px) {
    .ContactBtnModal {
        top: 3000px;
    }
}
@media (min-width: 1364px) and (max-width: 1366px) {
    .ContactBtnModal {
        top: 2800px;

    }
    .ContactBtnPortfolioModal {
        top: 1400px !important;
    }
}
@media (min-width: 1025px) and (max-width: 1364px) {
    .ContactBtnModal {
        top: 2650px;
    }
    .ContactBtnPortfolioModal {
        top: 1400px !important;
    }
}

@media (min-width: 1022px) and (max-width: 1025px) {
    .ContactBtnModal {
        top: 2550px;
        left: calc(50% + 15px);
    }
    .ContactBtnPortfolioModal {
        top: 1300px !important;
    }
}
@media (min-width: 745px) and (max-width: 1022px) {
    .ContactBtnModal {
        top: 2400px;
        left: calc(50% + 15px);
    }
}
@media (min-width: 1023px) and (max-width: 1365px) {
    .ContactBtn {
        width: 80px;
        height: 80px;
        margin-left: -40px;

        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 100px;
            height: 100px;
            margin-left: -50px;
            margin-top: -10px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }

        &Text {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
        }

        &ModalTop {
            top: 57px;
            left: 258px;
        }



        &PortfolioModal {
            top: 1300px ;
        }
    }
}

@media (min-width: 743px) and (max-width: 1023px) {
    .ContactBtn {
        width: 56px;
        height: 56px;
        margin-left: -28px;
        margin-top: 1px;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 64px;
            height: 64px;
            margin-left: -32px;
            margin-top: -3px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }

        &Text {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
        }

        &ModalTop {
            top: 38px;
            left: 180px;
        }


        &PortfolioModal {
            top: 1300px !important;
        }
    }
}

@media (min-width: 429px) and (max-width: 743px) {
    .ContactBtn {
        width: 56px;
        height: 56px;
        margin-left: -28px;
        margin-top: 1px;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 64px;
            height: 64px;
            margin-left: -32px;
            margin-top: -3px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }


        &Text {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
        }

        &Modal {
            top: 2400px;
            left: calc(50% - 177.5px);
        }

        &ModalTop {
            top: 100px;
            left: 58px;
        }

        &PortfolioModal {
            top: 880px !important;
        }
    }
}


@media (min-width: 360px) and (max-width: 429px) {
    .ContactBtn {
        width: 56px;
        height: 56px;
        margin-left: -28px;

        margin-top: 1px;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 62px;
            height: 62px;
            margin-left: -31px;
            margin-top: -3px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }

        &Text {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
        }

        &Modal {
            top: 2500px;
            left: calc(50% - 177.5px);
        }

        &ModalTop {
            top: 95px;
            left: 3px;
        }

        &PortfolioModal {
            top: 930px !important;
        }
    }
}


@media (min-width: 300px) and (max-width: 360px) {
    .ContactBtn {
        width: 56px;
        height: 56px;
        margin-left: -28px;

        margin-top: 1px;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 

        &:hover {
            width: 62px;
            height: 62px;
            margin-left: -31px;
            margin-top: -3px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        }

        &Text {
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
        }
    }
}


@media (min-width: 10px) and (max-width:300px) {
    .ContactBtn {
        display: none;
    }
}