@import url('https://fonts.cdnfonts.com/css/queen-love-stories-free');
@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');

* {
    box-sizing: border-box !important;
}

.Portfolio {
    overflow: hidden;
    position: relative;
    padding-bottom: 64px;
    @media (min-width: 430px) and (max-width: 1366px) {
        & {
            padding-bottom: 24px;
        }
    }
    @media (min-width: 320px) and (max-width: 430px) {
        & {
            padding-bottom: 34px;
        }
    }

    &HeaderContainer {
        height: 158px;
        margin-bottom: 48px;
        @media (min-width: 1024px) and (max-width: 1366px) {
            &{
                margin-bottom: 32px;
                height: 132px;
            }
        }
        @media (min-width: 744px) and (max-width: 1024px) {
            &{
                height: 88px;
                margin-bottom: 24px;
            }
        }
        @media (min-width: 300px) and (max-width: 744px) {
            &{
                height: 44px;
            }
        }
    }

    &HeaderWrapper {
        position: absolute;
        @supports (-webkit-animation: 190s  infinite linear forwards  floatText) {
            -webkit-animation: 190s  infinite linear forwards  floatText !important;
            @media (min-width: 744px) and (max-width: 1024px) {
                // animation: 160s  infinite linear forwards  floatText;
                -webkit-animation: 160s  infinite linear forwards  floatText !important;
            }
            @media (min-width: 1024px) and (max-width: 1366px) {
                -webkit-animation: 190s  infinite linear forwards  floatText !important;
                // animation: 190s  infinite linear forwards  floatText;
                // animation-duration: 190s;
            }
          }
          
        // -webkit-animation: 190s  infinite linear forwards  floatText !important;
        animation: 190s  infinite linear forwards  floatText;
        @media (min-width: 744px) and (max-width: 1024px) {
            animation: 160s  infinite linear forwards  floatText;
        }
        @media (min-width: 1024px) and (max-width: 1366px) {
            animation: 190s  infinite linear forwards  floatText;
        }
    }
    &Header {
        white-space: nowrap;
        top: 0;
        font-family: 'Queen Love Stories free';
        font-style: normal;
        font-weight: 400;
        font-size: 144px;
        line-height: 158px;
        color: #000000;
        @media (min-width: 1024px) and (max-width: 1366px) {
            &{
                font-size: 120px;
                line-height: 132px;
            }
        }
        @media (min-width: 744px) and (max-width: 1024px) {
            &{
                font-size: 80px;
                line-height: 88px;
            }
        }
        @media (min-width: 300px) and (max-width: 744px) {
            &{
                font-size: 40px;
                line-height: 44px;
            }
        }
    }

    &Swiper {
        display: flex !important;
        flex-direction: row !important;
        width: 100vw !important;
        padding-left: 80px !important;
        @media (min-width: 1512px) and (max-width: 1728px) {
            &{
                padding-left: 64px !important;
            }
        }
        @media (min-width: 1366px) and (max-width: 1512px) {
            &{
                padding-left: 64px !important;
            }
        }
        @media (min-width: 1024px) and (max-width: 1366px) {
            &{
                padding-left: 32px !important;
            }
        }
        @media (min-width: 744px) and (max-width: 1024px) {
            &{
                padding-left: 32px !important;
            }
        }
        @media (min-width: 300px) and (max-width: 744px) {
            &{
                padding-left: 16px !important;
            }
        }
    }
    &SwiperSlide {
        display: flex !important;
        align-items: flex-end !important;
        height: 408px !important;
        width: 524px !important;

        @media (min-width: 1728px) and (max-width: 1920px) {
            &{
                width: 478px !important;
                height: 377px !important;

            }
        }
        @media (min-width: 1512px) and (max-width: 1728px) {
            &{
                width: 411px !important;
                // height: 325px !important;
                height: auto !important;
                
            }
        }
        @media (min-width: 1366px) and (max-width: 1512px) {
            &{
                width: 371px !important;
                height: auto !important;
            }
        }
        
        @media (min-width: 320px) and (max-width: 1366px) {
            &{
                width: 273px !important;
                height: 274px !important;
            }
        }
    }
    &TagsSection {
        margin-left: 80px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-width: 932px;
        max-width: 1200px;
        height: auto;
        gap: 24px;
        margin-bottom: 56px;
        @media (min-width: 1366px) and (max-width: 1512px) {
            &{
                margin-left: 61px;
            }
        }
        @media (min-width: 1024px) and (max-width: 1366px) {
            &{
                min-width: 786px;
                max-width: 1000px;
                // height: 90px;     
                height: auto;
                margin-bottom: 40px;
                margin-left: 32px;

            }
        }
        @media (min-width: 744px) and (max-width: 1024px) {
            &{
                min-width: 551px;
                max-width: 660px;
                // height: 64px;
                height: auto;
                margin-left: 32px;
                gap: 16px;
            }
        }
        @media (min-width: 430px) and (max-width: 744px) {
            &{
                min-width: 384px;
                max-width: 425px;
                // height: 104px;
                height: auto;
                margin-left: 16px;
                gap: 16px;
            }
        }
        @media (min-width: 320px) and (max-width: 430px) {
            &{
                min-width: 268px;
                max-width: 320px;
                height: 144px;
                margin-left: 16px;
                gap: 16px;
            }
        }
    }
    &TagsSectionElement {
        font-family: 'Thabit';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        border: 2px solid #000000;
        border-radius: 20px;
        padding: 0px 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        cursor: pointer;

        &:last-child {
            display: none;
        }

        &Filter {
            background-color: black;
            color: white;
        }

        &ClearAll{
            display: flex !important; 
            color: #EC52EF;
            border: 2px solid #EC52EF;
            // padding: 0px 14px;
        }

        @media (min-width: 1024px) and (max-width: 1366px) {
            &{
                font-size: 16px;
                line-height: 21px;       
                height: 33px;         
            }
        }
        @media (min-width: 744px) and (max-width: 1024px) {
            &{
                font-size: 12px;
                line-height: 16px;
                height: 24px;
                padding: 0px 6px;
            }
        }
        @media (min-width: 300px) and (max-width: 744px) {
            &{
                font-size: 12px;
                line-height: 16px;
                padding: 0px 6px;
                height: 24px;
            }
        }
    }

    &Row {
        display: flex;
        flex-direction: row;
        gap: 56px;
        margin-top: 200px;
        margin-left: 20px;
        box-sizing: border-box !important;
    }
}


.swiper-slide { width: auto !important}



@keyframes floatText{
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(0);
    }
}

