@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');

.AboutUs {
    height: 1357px;
    margin-bottom: 32px;
    cursor: default !important;

    @media (min-width:430px) and (max-width: 744px) {
        height: 1417px;
    }

    @media (min-width:300px) and (max-width: 430px) {
        height: 1482px;
    }

    &MainSection {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        min-height: 1274px;
        background:
            linear-gradient(#000000 0 0) 50%;
        background-size: 2px 100%;
        background-repeat: no-repeat;

        @media (min-width:430px) and (max-width: 744px) {
            & {
                min-height: 1331px;
            }
        }

        @media (min-width:300px) and (max-width: 430px) {
            & {
                min-height: 1396px;
            }
        }

        &Right {
            width: 50%;
            height: 100%;

            @media (min-width:300px) and (max-width: 744px) {
                width: 100%;
                flex-direction: column;
                display: flex;
                z-index: 1;
                align-items: center;
            }

            &KeyText {
                margin-top: 439px;
                width: 500px;
                margin-left: 63px;
                height: 252px;
                font-family: 'Thabit';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;

                @media (min-width: 1366px) and (max-width:1512px) {
                    & {
                        margin-top: 439px;
                        margin-left: 63px;
                    }
                }

                @media (min-width: 1024px) and (max-width:1366px) {
                    & {
                        margin-top: 397px;
                        width: 416px;
                        height: 294px;
                    }
                }

                @media (min-width: 744px) and (max-width:1024px) {
                    & {
                        margin-top: 256px;
                        width: 307px;
                        height: 420px;
                        margin-left: 32px;
                    }
                }

                @media (min-width:430px) and (max-width: 744px) {
                    & {
                        text-align: center;
                        margin-top: 220px;
                        padding-top: 80px;
                        padding-bottom: 80px;
                        z-index: 1;
                        background-color: white;
                        width: 397px;
                        height: fit-content;
                        margin-left: 0px;
                    }
                }

                @media (min-width:320px) and (max-width: 430px) {
                    & {
                        text-align: center;
                        margin-top: 220px;
                        padding-top: 80px;
                        padding-bottom: 80px;
                        z-index: 2;
                        background-color: white;
                        min-width: 330px;
                        max-width: 80%;
                        height: 538px;
                        margin-left: 0px;
                    }
                }

            }

            &CredoWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 360px;
                margin-left: 63px;
                gap: 120px;

                @media (min-width: 744px) and (max-width:1024px) {
                    & {
                        gap: 49px;
                        margin-left: 32px;
                    }
                }

                @media (min-width:430px) and (max-width: 744px) {
                    & {
                        gap: 48px;
                        margin-left: 0px;
                        padding-top: 80px;
                        padding-bottom: 80px;
                        margin-top: 220px;
                        z-index: 1;
                        background-color: white;
                    }
                }

                @media (min-width:300px) and (max-width: 430px) {
                    & {
                        gap: 48px;
                        margin-left: 0px;
                        padding-top: 80px;
                        padding-bottom: 80px;
                        margin-top: 220px;
                        z-index: 1;
                        min-width: 276px;
                        background-color: white;
                    }
                }
            }

            &Credo,
            &CredoText {
                font-family: 'Thabit';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                cursor: default !important;
            }

            &CredoTextWrapper {
                display: flex;
                flex-direction: column;
                gap: 48px;
            }

            &CredoText:hover {
                color: #38D9B2;
                font-weight: 700;

                @media (max-width:1024px) {
                    & {
                        color: #000000;
                        font-weight: 500;
                    }
                }
            }
        }

        &Left {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;

            @media (min-width:430px) and (max-width: 744px) {
                display: none;
            }

            &ImageCreation {
                margin-top: 286px;
                width: 448px;
                height: 305px;
                visibility: hidden;
                opacity: 0;
                // transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
                position: absolute;

                @media (max-width:1024px) {
                    & {
                        display: none;
                    }
                }
            }

            &ImageAttention {
                margin-top: 338px;
                width: 452px;
                height: 194px;
                visibility: hidden;
                opacity: 0;
                // transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
                position: absolute;

                @media (max-width:1024px) {
                    & {
                        display: none;
                    }
                }
            }

            &ImageLove {
                margin-top: 300px;
                width: 451px;
                height: 269px;
                visibility: hidden;
                opacity: 0;
                position: absolute;

                // transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
                @media (max-width:1024px) {
                    & {
                        display: none;
                    }
                }
            }
        }
    }

    &Text {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 61px;
        flex-direction: row;
        width: 100%;
        font-family: 'Thabit';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
    }

    &TextSection {
        height: 100%;
    }

    &TextDot {
        width: 30px;
        height: 30px;
        background: #38D9B2;
        border-radius: 120px;
        position: absolute;
        left: 50%;
        margin-left: -15px;
    }
}