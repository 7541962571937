.EshkaTaxi {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 371px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 247px;
        height: 371px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 141px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 115px;
            height: 174px;
        }
    }

    &Image3 {
        width: 530px;
        height: 430px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 363px;
            height: 295px;
            // object-fit: fill;
        }

        @media (min-width:360px) and (max-width: 430px) {
            // width: 241px;
            // height: 218px;
            // object-fit: fill;
            width: 269px;
            height: 218px;
        }
    }

    &Image4 {
        width: 530px;
        height: 430px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 305px;
            // height: 295px;
            // object-fit: fill;
            width: 363px;
            height: 295px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 269px;
            height: 218px;
        }
    }

    &Image5 {
        width: 502px;
        height: 366px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 288px;
            height: 209px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 235px;
            height: 170px;
        }
    }

    &Image6 {
        width: 530px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 363px;
            height: 295px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 269px;
            height: 218px;
        }
    }

    &Image7 {
        width: 465px;
        height: 370px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 266px;
            height: 212px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 217px;
            height: 173px;
        }
    }

    &Image8 {
        width: 530px;
        height: 431px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 362px;
            height: 295px;
            object-fit: cover;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 269px;
            height: 218px;
            
        }
    }

    &Image9 {
        width: 499px;
        height: 370px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 285px;
            height: 212px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 232px;
            height: 173px;
        }
    }

    &Image10 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 241px;
            height: 294px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 196px;
            height: 218px;
        }
    }

    &Image11 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 240px;
            height: 276px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 196px;
            height: 218px;
        }
    }

    &Image12 {
        width: 420px;
        height: 431px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 240px;
            height: 276px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 196px;
            height: 219px;
        }
    }
}