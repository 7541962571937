.Mysimplelog {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            //0.57
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            //0.467
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 551px;
        height: 424px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 242px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 198px;
        }
    }

    &Image3 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image4 {
        width: 551px;
        height: 367px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 209px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 171px;
        }
    }

    &Image5 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image6 {
        width: 551px;
        height: 391px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 222px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 182px;
        }
    }

    &Image7 {
        width: 551px;
        height: 345px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 196px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 161px;
        }
    }

    &Image8 {
        width: 551px;
        height: 291px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 166px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 136px;
        }
    }

    &Image9 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }

    &Image10 {
        width: 551px;
        height: 418px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 238px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 195px;
        }
    }

    &Image11 {
        width: 551px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 200px;
        }
    }
}