@import url('https://fonts.cdnfonts.com/css/queen-love-stories-free');
@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');

@font-face {
    font-family: myFirstFont;
    src: url(../fonts/icomoon.ttf);
}

.Header {
    width: 100%;
    background-size: 1914px 930px !important;
    background: url(../images/motherland.png) 750px 0% no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;



    @media (min-width: 1727px) and (max-width: 1919px) {
        background-size: 1784px 870px !important;
        background: url(../images/motherland.png) 685px 0% no-repeat;
    }

    @media (min-width: 1511px) and (max-width: 1727px) {
        background-size: 1708px 830px !important;
        background: url(../images/motherland.png) 600px 0% no-repeat;
    }

    @media (min-width: 1365px) and (max-width: 1511px) {
        background-size: 1440px 700px !important;
        background: url(../images/motherland.png) 570px 0% no-repeat;
    }

    @media (min-width: 1023px) and (max-width: 1222px) {
        background-size: 1114px 542px !important;
        background: url(../images/motherlandUncut1024.png) 420px 0% no-repeat;
    }

    @media (min-width: 1223px) and (max-width: 1365px) {
        background-size: 1114px 542px !important;
        background: url(../images/motherlandUncut1024.png) 420px 0% no-repeat;
    }

    @media (min-width: 901px) and (max-width: 1023px) {
        background-size: 798px 388px !important;
        background: url(../images/motherlandUncut1024.png) 301px 0% no-repeat;
    }

    @media (min-width: 743px) and (max-width: 900px) {
        background-size: 798px 388px !important;
        background: url(../images/motherlandUncut1024.png) 301px 0% no-repeat;
    }

    @media (min-width: 662px) and (max-width: 743px) {
        background-size: 798px 388px !important;
        background: url(../images/motherlandUncut1024.png) 62px 0% no-repeat;
    }

    @media (min-width: 429px) and (max-width: 662px) {
        background-size: 798px 388px !important;
        background: url(../images/motherlandUncut1024.png) 62px 0% no-repeat;
    }


    @media (min-width: 320px) and (max-width: 429px) {
        background-size: 732px 356px !important;
        background: url(../images/motherlandUncut1024.png) 35px 0% no-repeat;
    }

    &Container {
        display: flex;
        height: 930px;

        @media (min-width: 1727px) and (max-width: 1919px) {
            height: 871px;
        }
    
        @media (min-width: 1511px) and (max-width: 1727px) {
            height: 830px;
        }
    
        @media (min-width: 1365px) and (max-width: 1511px) {
            height: 700px;
        }
    
        @media (min-width: 1023px) and (max-width: 1365px) {
            height: 542px;
        }
    
        @media (min-width: 743px) and (max-width: 1023px) {
            height: 388px;
        }
    
        @media (min-width: 429px) and (max-width: 743px) {
            height: 388px;
        }
    
        @media (min-width: 320px) and (max-width: 429px) {
            height: 356px;
        }
    }

    &LeftContainer {
        display: flex;
        flex-direction: column;

        &Lines {
            width: 129px;
            height: 46px;
            margin-top: 88px;
            margin-left: 80px;
            

            @media (min-width: 1727px) and (max-width: 1919px) {
                margin-top: 79px;
                margin-left: 80px;
            }
        
            @media (min-width: 1511px) and (max-width: 1727px) {
                margin-top: 111px;
                margin-left: 64px;
            }
        
            @media (min-width: 1365px) and (max-width: 1511px) {
                margin-top: 56px;
                margin-left: 61px;
            }
        
            @media (min-width: 1023px) and (max-width: 1365px) {
                margin-top: 40px;
                margin-left: 32px;
                width: 65px;
                height: 31px;
            }
        
            @media (min-width: 743px) and (max-width: 1023px) {
                margin-top: 13px;
                margin-left: 31px;
                width: 65px;
                height: 31px;
            }
        
            @media (min-width: 429px) and (max-width: 743px) {
                display: none;
            }
        
            @media (min-width: 320px) and (max-width: 429px) {
                display: none;  
            }
        }
    }

    &LeftContainerWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;
        position: static !important;
        flex-direction: column;
    }

    &LeftContainerLogo {
        width: 625.71px !important;
        height: 554px !important;
    }

    &LeftContainerWrapperLetters {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    &LeftContainerLetterS {
        background-size: cover;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: transparent;
        background-size: cover;
        background-clip: text;
        color: black;
        mix-blend-mode: color-dodge;
        font-family: 'myFirstFont';
        -webkit-text-stroke: 2px #fff;
        margin-right: 48.5px;
        font-size: 554px;

        &::before {
            content: "\e902";
        }

        @media (min-width: 1727px) and (max-width: 1919px) {
            font-size: 507px;
            margin-right: 44px;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            font-size: 436px;
            margin-right: 38px;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            font-size: 413px;
            margin-right: 36px;
        }

        @media (min-width: 1023px) and (max-width: 1365px) {
            font-size: 319px;
            margin-right: 28px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            font-size: 226px;
            margin-right: 20px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            font-size: 180px;
            margin-right: 16px;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            font-size: 157px;
            margin-right: 13.7px;
        }
    }

    &LeftContainerLetterI {
        background-size: cover;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: transparent;
        background-size: cover;
        background-clip: text;
        color: black;
        mix-blend-mode: color-dodge;
        font-family: 'myFirstFont';
        -webkit-text-stroke: 2px #fff;
        font-size: 554px;

        &::before {
            content: "\e901";
        }

        @media (min-width: 1727px) and (max-width: 1919px) {
            font-size: 507px;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            font-size: 436px;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            font-size: 413px;
        }

        @media (min-width: 1023px) and (max-width: 1365px) {
            font-size: 319px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            font-size: 226px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            font-size: 180px;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            font-size: 157px;
        }
    }

    &LeftContainerLetterD {
        background-size: cover;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: transparent;
        background-size: cover;
        background-clip: text;
        color: black;
        mix-blend-mode: color-dodge;
        font-family: 'myFirstFont';
        -webkit-text-stroke: 2px #fff;
        margin-left: 90px;
        font-size: 526px;

        &::before {
            content: "\e900";
        }

        margin-bottom: 27.53px;

        @media (min-width: 1727px) and (max-width: 1919px) {
            margin-bottom: 25.53px;
            font-size: 481px;
            line-height: 481px;
            margin-left: 83px;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            margin-bottom: 21.7px;
            font-size: 414px;
            margin-left: 71px;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            margin-bottom: 20.5px;
            font-size: 392px;
            line-height: 392px;
            margin-left: 67px;
        }

        @media (min-width: 1023px) and (max-width: 1365px) {
            margin-bottom: 16px;
            font-size: 303px;
            line-height: 303px;
            margin-left: 52px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            margin-bottom: 10.7px;
            font-size: 215px;
            margin-left: 37px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            margin-bottom: 9px;
            font-size: 170px;
            margin-left: 29px;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            margin-bottom: 7.8px;
            font-size: 149px;
            margin-left: 25px;
        }
    }

    &ContactBtnWrapper {
        width: fit-content;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
    }


    &RightContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;
    }
    &RightContainerTop {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        gap: 8px;
        padding-right: 80px;
        align-items: flex-end;

        @media (min-width: 1727px) and (max-width: 1919px) {
            padding-right: 64px;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            padding-right: 64px;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            padding-right: 64px;
        }

        @media (min-width: 1023px) and (max-width: 1365px) {
            padding-right: 32px;
            margin-top: 56px;
            gap: 12px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            padding-right: 32px;
            margin-top: 16px;
            gap: 12px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            padding-right: 16px;
            margin-top: 16px;
            gap: 13px;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            padding-right: 14px;
            margin-top: 16px;
            gap: 13px;
        }
    }
    &RightContainerTime {
        font-family: 'Thabit', 'sans-serif';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: white;


        @media (min-width: 743px) and (max-width: 1023px) {
            font-size: 12px;
        }

        @media (min-width:429px) and (max-width: 743px) {
            position: absolute;
            font-size: 12px;
            right: 16px;
            &:nth-child(2) {
                top: 45px;
            }
        }
        @media (min-width:320px) and (max-width: 429px) {
            position: absolute;
            font-size: 12px;
            right: 14px;
            &:nth-child(2) {
                top: 45px;
            }
        }
    }
    &RightContainerBottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        position: absolute;
        left: calc(50% - 1px);
        width: 50%;
        top: 783px;
        padding-right: 64px;
        height: 120px;
        padding-bottom: 24px;

        @media (min-width: 1727px) and (max-width: 1919px) {
            position: absolute;
            left: calc(50% - 1px);
            width: 50%;
            top: 720px;
            padding-right: 64px;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            position: absolute;
            left: calc(50% - 1px);
            width: 50%;
            top: 696px;
            padding-right: 64px;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            position: absolute;
            left: calc(50% - 1px);
            width: 50%;
            top: 562px;
            padding-right: 64px;
        }

        @media (min-width: 1023px) and (max-width: 1365px) {
            position: absolute;
            left: calc(50% - 1px);
            width: 50%;
            top: 420px;
            padding-right: 32px;
            height: 80px;
            padding-bottom: 15px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            position: absolute;
            left: calc(50% - 1px);
            width: 50%;
            top: 280px;
            padding-right: 32px;

        }

        @media (min-width: 429px) and (max-width: 743px) {
            margin-bottom: 40px;
            margin-right: 32px; 
            position: static;
            height: auto;
            padding-bottom: 0;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            margin-bottom: 56px;
            position: static;
            height: auto;
            padding-bottom: 0;
        }
    }
    .HeaderRightContainerArrowDiv {
        // width: 64px;
        // height: 64px;
        box-sizing: border-box;
        cursor: pointer;
        width: 96px;
        height: 96px;
        position: relative;
        border-radius: 50%;
        border: 2px solid var(--White, #FFF);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.8s cubic-bezier(.215,.61,.355,1); 
        // transition: all 1s linear;
        // transition: all 0.8s ease-in-out;
        &:hover {
            width: 120px;
            height: 120px;
            transition: all 0.8s cubic-bezier(.215,.61,.355,1);
            margin-right: -12.6px;
            // margin-right: -10.6px;
        }
        @media (min-width: 1023px) and (max-width: 1365px) {
            width: 64px;
            height: 64px;
            margin-right: -1px;
            border: 1.5px solid var(--White, #FFF);
            &:hover {
                width: 80px;
                height: 80px;
                transition: all 0.8s cubic-bezier(.215,.61,.355,1);
                // transition: all 1s linear;
                // margin-right: -8.43px;  
                // margin-right: -8px;  
                margin-right: -9.11px;  
            }
        }
        @media (min-width: 743px) and (max-width: 1023px) {
            width: 64px;
            height: 64px;
            margin-right: -1px;
            border: 1.5px solid var(--White, #FFF);
            &:hover {
                width: 80px;
                height: 80px;
                transition: all 0.8s cubic-bezier(.215,.61,.355,1);
                // transition: all 1s linear;
                // margin-right: -8.43px;  
                // margin-right: -8px;  
                margin-right: -9.11px;  
            }
        }
        @media (min-width: 429px) and (max-width: 743px) {
            display: none;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            display: none;
        }
    }
    &RightContainerArrow {
        cursor: pointer;
        // width: 96px;
        // height: 96px;
        width: 20px;
        height: 32px;
        // position: absolute;
        margin: 0 auto;
        align-self: center;
        // top: 22px;


        @media (min-width: 1023px) and (max-width: 1365px) {
            // width: 64px;
            // height: 64px;
            width: 13px;
            height: 23px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            // width: 64px;
            // height: 64px;
            width: 13px;
            height: 23px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            display: none;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            display: none;
        }
    }

    &RightContainerText {
        font-family: 'Thabit', 'sans-serif';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 0;

        @media (min-width: 1727px) and (max-width: 1919px) {
            margin-left: 0;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            margin-left: 0;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            margin-left: 0;
        }

        @media (min-width: 1250px) and (max-width: 1365px) {
            margin-left: 0;
        }

        @media (min-width: 1100px) and (max-width: 1250px) {
            margin-left: 0;
        }

        @media (min-width: 1023px) and (max-width: 1100px) {
            margin-left: 0;
        }

        @media (min-width: 835px) and (max-width: 1023px) {
            margin-left: 0;
            font-size: 12px;
        }
        
        @media (min-width: 743px) and (max-width: 835px) {
            margin-left: 0;
            font-size: 12px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            margin-left: 0;
            position: absolute;
            right: 28px;
            height: auto;
            font-size: 12px;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            margin-left: 0;
            position: absolute;
            right: 14px;
            height: auto;
            font-size: 12px;
        }
    }

    &ContactBtn {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        margin-top: 14px;
        margin-bottom: 24px;


        @media (min-width: 1727px) and (max-width: 1919px) {
            margin-top: 18px;
            margin-bottom: 24px;
            height: 140px;
        }

        @media (min-width: 1511px) and (max-width: 1727px) {
            margin-top: 53px;
            margin-bottom: 24px;
            height: 120px;
        }

        @media (min-width: 1365px) and (max-width: 1511px) {
            margin-top: 28px;
            margin-bottom: 16px;
            height: 100px;
        }

        @media (min-width: 1023px) and (max-width: 1365px) {
            margin-top: 16px;
            margin-bottom: 16px;
            height: 80px;
        }

        @media (min-width: 743px) and (max-width: 1023px) {
            margin-top: 9px;
            margin-bottom: 16px;
            height: 56px;
        }

        @media (min-width: 429px) and (max-width: 743px) {
            margin-top: 45px;
            margin-bottom: 16px;
            height: 56px;
        }

        @media (min-width: 320px) and (max-width: 429px) {
            margin-top: 32px;
            margin-bottom: 12px;
            height: 56px;
        }
    }


    &TextDot {
        width: 30px;
        height: 30px;
        background: #38D9B2;
        border-radius: 120px;
        position: relative;
        left: 50%;
        margin-left: -15px;
    }

    &TextOffer {
        margin-bottom: 80px;
        margin-top: 32px;
        font-family: 'Thabit', 'sans-serif';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        line-height: 21px;


        @media (min-width:744px) and (max-width:1024px) {
            & {
                margin-bottom: 64px;
            }
        }

        @media (min-width:320px) and (max-width:744px) {
            & {
                margin-top: 24px;
                margin-bottom: 64px;
            }
        }
    }


    &Bottom {
        display: flex;
        flex-direction: column;
    }

    &Line {
        height: 98px;
        background:
            linear-gradient(#000000 0 0) 50%;
        background-size: 2px 100%;
        background-repeat: no-repeat;
    }
}

.HeaderSafari {
    background: none;
    background-size: 1920px 930px !important;
}

.HeaderContainerSafari {
    background: url(../images/SafariHeader/1920.png) 0px 0px no-repeat !important;
    background-size: cover !important;

    @media (min-width: 1727px) and (max-width: 1919px) {
        background: url(../images/SafariHeader/1728.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }

    @media (min-width: 1511px) and (max-width: 1727px) {
        background: url(../images/SafariHeader/1512.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }

    @media (min-width: 1365px) and (max-width: 1511px) {
        background: url(../images/SafariHeader/1366.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }

    @media (min-width: 1023px) and (max-width: 1365px) {
        background: url(../images/SafariHeader/1024.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }

    @media (min-width: 743px) and (max-width: 1023px) {
        background: url(../images/SafariHeader/744.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }

    @media (min-width: 429px) and (max-width: 743px) {
        background: url(../images/SafariHeader/430.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }

    @media (min-width: 320px) and (max-width: 429px) {
        background: url(../images/SafariHeader/360.png) 0px 0px no-repeat !important;
        background-size: cover !important;
    }
}