.ValleyList {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;
        object-fit: contain;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image3 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image4 {
        width: 558px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 319px;
            height: 213px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 259px;
            height: 174px;
        }
    }

    &Image5 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image6 {
        width: 420px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:360px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }
}