@import url('https://fonts.cdnfonts.com/css/queen-love-stories-free');
@import url('https://fonts.googleapis.com/earlyaccess/thabit.css');

.PortfolioPage {
    scroll-behavior: smooth !important;

    .PortfolioPageDesignBtnWrapper {
        display: flex;
        justify-content: center;
        margin-top: 46px;
    }
    .PortfolioPageDesignBtn {
        font-family: 'Thabit';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #000000;
        border: 2px solid #000000;
        border-radius: 20px;
        padding: 0px 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        text-decoration: none;
    }
    .PortfolioSwiperSlide {
        &:active {
            cursor: grab;
        }
        padding-right: 64px ;
        @media (min-width:430px) and (max-width: 744px) {
            padding-right: 40px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            padding-right: 32px;
        }
    }

    &Header {
        height: 500px;

        @media (min-width: 1728px) and (max-width: 1920px) {
            & {
                height: 433px;
            }
        }

        @media (min-width: 1512px) and (max-width: 1728px) {
            & {
                height: 400px;
            }
        }

        @media (min-width: 1366px) and (max-width: 1512px) {
            & {
                height: 350px;
            }
        }

        @media (min-width: 1024px) and (max-width: 1366px) {
            & {
                height: 803px;
            }
        }

        @media (min-width: 744px) and (max-width: 1024px) {
            & {
                height: 702px;
            }
        }

        @media (min-width: 430px) and (max-width: 744px) {
            & {
                height: 535px;
            }
        }

        @media (min-width: 320px) and (max-width: 430px) {
            & {
                height: 421px;
            }
        }

        .PortfolioPageBackBtnWrapper {
            height: 231px;


            @media (min-width: 1728px) and (max-width: 1920px) {
                & {
                    height: 175px;
                }
            }

            @media (min-width: 1512px) and (max-width: 1728px) {
                & {
                    height: 134px;
                }
            }

            @media (min-width: 1366px) and (max-width: 1512px) {
                & {
                    height: 118px;
                }
            }

            @media (min-width: 1024px) and (max-width: 1366px) {
                & {
                    height: 341px;
                }
            }

            @media (min-width: 744px) and (max-width: 1024px) {
                & {
                    height: 280px;
                }
            }

            @media (min-width: 430px) and (max-width: 744px) {
                & {
                    height: 168px;
                }
            }

            @media (min-width: 320px) and (max-width: 430px) {
                & {
                    height: 93px;
                }
            }
        }

        .PortfolioPageBackBtn {
            width: 47px !important;
            height: 47px !important;
            margin-left: 80px;
            margin-top: 40px;
            margin-bottom: 144px;
            cursor: pointer;

            @media (min-width: 1728px) and (max-width: 1920px) {
                & {
                    margin-bottom: 88px;
                }
            }

            @media (min-width: 1512px) and (max-width: 1728px) {
                & {
                    margin-bottom: 47px;
                    margin-left: 61px;
                }
            }

            @media (min-width: 1366px) and (max-width: 1512px) {
                & {
                    margin-bottom: 31px;
                    margin-left: 64px;
                }
            }

            @media (min-width: 1024px) and (max-width: 1366px) {
                & {
                    margin-bottom: 254px;
                    margin-left: 61px;
                }
            }

            @media (min-width: 744px) and (max-width: 1024px) {
                & {
                    margin-bottom: 189px;
                    margin-left: 61px;
                    margin-top: 44px;
                }
            }

            @media (min-width: 430px) and (max-width: 744px) {
                & {
                    margin-bottom: 97px;
                    margin-left: 16px;
                    margin-top: 24px;
                }
            }

            @media (min-width: 320px) and (max-width: 430px) {
                & {
                    margin-bottom: 27px;
                    margin-left: 16px;
                    margin-top: 19px;
                }
            }
        }

        &Info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 80px;
            margin-right: 396px;
            @media (min-width: 1728px) and (max-width: 1920px) {
                & {
                    margin-right: 300px;
                }
            }

            @media (min-width: 1512px) and (max-width: 1728px) {
                & {
                    margin-right: 192px;
                    margin-left: 61px;
                }
            }

            @media (min-width: 1366px) and (max-width: 1512px) {
                & {
                    margin-right: 119px;
                    margin-left: 64px;
                }
            }

            @media (min-width: 1024px) and (max-width: 1366px) {
                & {
                    flex-direction: column;
                    margin-left: 32px;
                    margin-right: 0px;
                    gap: 42px;
                }
            }

            @media (min-width: 744px) and (max-width: 1024px) {
                & {
                    flex-direction: column;
                    margin-left: 61px;
                    margin-right: 0px;
                    gap: 33px;
                }
            }

            @media (min-width: 430px) and (max-width: 744px) {
                & {
                    flex-direction: column;
                    margin-left: 16px;
                    margin-right: 0px;
                    gap: 16px;
                    margin-bottom: 97px;
                }
            }

            @media (min-width: 320px) and (max-width: 430px) {
                & {
                    flex-direction: column;
                    margin-left: 16px;
                    margin-right: 0px;
                    gap: 8px;
                }
            }

            &Title {
                font-family: 'Queen Love Stories free';
                font-style: normal;
                font-weight: 400;
                font-size: 144px;
                line-height: 158px;

                @media (min-width: 1024px) and (max-width: 1512px) {
                    & {
                        font-size: 120px;
                        line-height: 132px;
                    }
                }

                @media (min-width: 744px) and (max-width: 1024px) {
                    & {
                        font-size: 80px;
                        line-height: 88px;
                    }
                }

                @media (min-width: 320px) and (max-width: 744px) {
                    & {
                        font-size: 40px;
                        line-height: 44px;
                    }
                }
            }

            &Text {
                font-family: 'Thabit';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                width: 500px;
                height: auto;
                text-align: left;

                @media (min-width: 430px) and (max-width: 744px) {
                    & {
                        width: 398px;
                    }
                }

                @media (min-width: 320px) and (max-width: 430px) {
                    & {
                        width: 328px;
                    }
                }
            }
        }
    }

    &Text {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 32px;
        flex-direction: row;
        width: 100%;
        font-family: 'Thabit';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
    }

    &TextDot {
        width: 30px;
        height: 30px;
        background: #38D9B2;
        border-radius: 120px;
        position: absolute;
        left: 50%;
        margin-left: -15px;
    }

    &Line {
        height: 105px;
        background:
            linear-gradient(#000000 0 0) 50%;
        background-size: 2px 100%;
        background-repeat: no-repeat;
        margin-top: 40px;
    }

    .PortfolioSwiperSlide {
        width: fit-content;
    }

    .PortfolioSwiper {
        height: 430px !important;
        padding-left: 80px;

        @media (min-width: 1512px) and (max-width: 1728px) {
            padding-left: 61px;
        }

        @media (min-width: 1366px) and (max-width: 1512px) {
            padding-left: 64px;
        }

        @media (min-width: 1024px) and (max-width: 1366px) {
            padding-left: 32px;
        }

        @media (min-width: 300px) and (max-width: 745px) {
            padding-left: 16px;
        }

        @media (min-width: 745px) and (max-width: 1024px) {
            padding-left: 61px;
        }

        @media (min-width: 430px) and (max-width: 744px) {
            max-height: 295px !important;
            height: auto !important;
        }

        @media (min-width: 300px) and (max-width: 430px) {
            max-height: 219px !important;
            height: auto !important;

        }
    }

}