.ArgusSoft {
    overflow: hidden;
    &Image1 {
        width: 551px;
        height: 372px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 257px;
            height: 174px;
        }

    }

    &Image2 {
        width: 524px;
        height: 372px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 299px;
            height: 213px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 243px;
            height: 174px;
        }
    }

    &Image3 {
        width: 420px;
        height: 430px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image4 {
        width: 420px;
        height: 403px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 230px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 195px;
            height: 187px;
        }
    }

    &Image5 {
        width: 420px;
        height: 430px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 245px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 195px;
            height: 200px;
        }
    }

    &Image6 {
        width: 420px;
        height: 404px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 239px;
            height: 230px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 195px;
            height: 187px;
        }
    }

    &Image7 {
        width: 552px;
        height: 372px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 315px;
            height: 213px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 256px;
            height: 174px;
        }
    }

    &Image8 {
        width: 200px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 137px;
            height: 295px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 102px;
            height: 219px;
        }
    }

    &Image9 {
        width: 200px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            width: 137px;
            height: 295px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 102px;
            height: 219px;
        }
    }

    &Image10 {
        width: 558px;
        height: 372px;
        object-fit: cover;

        @media (min-width:430px) and (max-width: 744px) {
            width: 318px;
            height: 213px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 259px;
            height: 174px;
        }
    }

    &Image11 {
        width: 530px;
        height: 430px;

        @media (min-width:430px) and (max-width: 744px) {
            // width: 302px;
            // height: 295px;
            width: 363px;
            height: 295px;
        }

        @media (min-width:320px) and (max-width: 430px) {
            width: 269px;
            height: 219px;
        }
    }

}